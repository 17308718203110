import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {Helmet} from 'react-helmet'
import './assets/App.scss'
import {Footer, Header, Modal, SystemMessages} from 'components'

const HtmlHead = props => (
  <Helmet>
    <title>{props.title}</title>
    <meta name="description" content={props.description} />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href={require('./assets/images/favicon.png')}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href={require('./assets/images/favicon.png')}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href={require('./assets/images/favicon.png')}
    />
  </Helmet>
)
export const LayoutDefault = props => (
  <div className="light">
    <HtmlHead title={props.title} description={props.description} />
    <SystemMessages />
    <Header />
    <div
      className="page"
      data-spy="scroll"
      data-target="#header"
      data-offset="0">
      {props.children}
    </div>
    <Footer />
    <Modal />
  </div>
)

export const LayoutNoHeader = props => (
  <div className="light">
    <HtmlHead title={props.title} description={props.description} />
    <SystemMessages />
    <div
      className="page"
      data-spy="scroll"
      data-target="#header"
      data-offset="0">
      {props.children}
    </div>
    <Modal />
  </div>
)

export const LayoutGuest = props => (
  <div className="light">
    <HtmlHead title={props.title} description={props.description} />
    <Header />
    <div
      className="page"
      data-spy="scroll"
      data-target="#header"
      data-offset="0">
      {props.children}
    </div>
    <Modal />
  </div>
)
