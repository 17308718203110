import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {MdArrowDropDown, MdArrowDropUp} from 'react-icons/all'
import {useParams} from 'react-router-dom'
import {Button} from 'materials'

export const CountryMenu = inject('UIStore')(
  observer(props => {
    const isOpen = props.UIStore.dropdown.Countrymenu

    const {country = 'us'} = useParams()

    useEffect(() => {
      document.documentElement.className = ''
      document.documentElement.classList.add(`theme-${country}`)
      console.log('Theme updated...')
    }, [country])

    const toggleMenu = e => {
      if (e) e.stopPropagation()
      props.UIStore.toggleDropDown('Countrymenu')
      window.addEventListener('click', () => {
        props.UIStore.toggleDropDown('Countrymenu', false)
      })
    }
    const changeCountry = async e => {
      e.stopPropagation()
      const country = e.currentTarget.dataset.value
      await localStorage.setItem('country', country)
      window.location = `/${country}`
    }
    return (
      <div
        className={
          'menu right horizon between stretch pl-10 pr-10 ' +
          (isOpen ? 'opened-menu' : '')
        }>
        <div
          className="menu-button horizon between middle"
          onClick={toggleMenu}>
          <div className="vertical left middle">
            <p className="sz-14  f-white">Country</p>
            <span style={{textTransform: 'uppercase'}}>{country}</span>
          </div>
          <MdArrowDropDown
            className={'f-white ' + (isOpen ? 'hide' : 'show')}
          />
          <MdArrowDropUp className={'f-white ' + (isOpen ? 'show' : 'hide')} />
        </div>
        <div
          className={
            'menu-dropdown horizon between stretch shadow-box ' +
            (isOpen ? 'show' : 'hide')
          }>
          <div className="links vertical left middle">
            <Button data-value="us" onClick={changeCountry} className="country">
              <span>US</span>
            </Button>
            <Button data-value="uk" onClick={changeCountry} className="country">
              <span>UK</span>
            </Button>
          </div>
        </div>
      </div>
    )
  })
)
