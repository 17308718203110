import {inject, observer} from 'mobx-react'
import moment from 'moment'
import {qs} from 'helpers'
import React from 'react'
import {MdCheck, MdClose, RiStickyNote2Line} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {DateTime, WebsiteOpener} from '../'
import {Loader} from '../Loader'
import {EmptyBoard} from 'materials'

export const Transaction = inject(
  'UIStore',
  'TransactionStore',
  'ProfileStore'
)(
  observer(props => {
    const history = useHistory()
    const {id} = props
    const {search} = useLocation()
    const params = qs.parse(search)

    const {today, oneWeekAgo} = props.ProfileStore
    const {item, detailState} = props.TransactionStore

    const from = new Date(
      params.from || props.UIStore.from || oneWeekAgo.startOf('days').toDate()
    )
    const to = new Date(
      params.to || props.UIStore.to || today.endOf('days').toDate()
    )

    const startTime =
      moment(from).subtract(from.getTimezoneOffset(), 'minutes').unix() * 1000
    const endTime =
      moment(to).subtract(to.getTimezoneOffset(), 'minutes').unix() * 1000

    useDeepCompareEffect(() => {
      props.TransactionStore.read({
        params: {
          ...params,
          search: JSON.stringify([{field: 'user_id', q: [id], include: true}]),
          from: startTime,
          to: endTime,
        },
        onModal: true,
      })
    }, [props.TransactionStore, id])

    // const checkCodeUsed = (successful_codes, used_code) => {
    //   for (let item of successful_codes) {
    //     if (item.code === used_code?.code) return true;
    //   }
    //   return false;
    // };

    return (
      <div className="p-10 vertical left stretch">
        {detailState !== 'done' ? (
          <div className="mb-40 mt-20">
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            {item.length > 0 ? (
              <React.Fragment>
                <div
                  id="scrollBar"
                  className="horizon between stretch per-100 modal-scroll-body mt-10">
                  <table className="list-table-modal mt-20">
                    <thead>
                      <tr>
                        <td>DATE TIME</td>
                        <td>MERCHANT URL</td>
                        <td>COUPON CODE</td>
                        <td>DISCOUNT APPLIED</td>
                        <td>TOTAL PRICE</td>
                        <td>SAVING AMOUNT</td>
                        <td>FINAL PRICE</td>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map(i => (
                        <tr key={'transaction-' + i.id}>
                          <td nowrap={'nowrap'}>
                            <DateTime
                              timestamp={i.timestamp}
                              format={'MMM D YYYY | hh:mm A'}
                            />
                          </td>
                          {/*<td nowrap={"nowrap"}>*/}
                          {/*	<UserIdButton id={i.user_id}/>*/}
                          {/*</td>*/}
                          <td>
                            <WebsiteOpener url={i.website_url} />
                          </td>
                          <td>{i.coupon_code}</td>
                          <td>
                            {i.is_discounted ? (
                              <MdCheck className="status active" />
                            ) : (
                              <MdClose className="status inactive" />
                            )}
                          </td>
                          <td>{i.old_price}</td>
                          <td>{i.discount}</td>
                          <td>{i.new_price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            ) : (
              <div className="per-100 vertical center middle">
                <EmptyBoard
                  icon={<RiStickyNote2Line />}
                  description="This user has no transaction history for this week"
                />
              </div>
            )}
            {item.length > 0 && (
              <button
                className="btn small submit mt-10 per-100"
                name="SEE ALL"
                onClick={() => {
                  props.UIStore.modalClose()
                  history.push({
                    pathname: '/transactions',
                    search: '?user_id=' + id,
                  })
                }}>
                {' '}
                See All{' '}
              </button>
            )}
          </React.Fragment>
        )}
      </div>
    )
  })
)
