import {InputBase, withStyles} from '@material-ui/core'
import React from 'react'
import {useFormContext} from 'react-hook-form'

const StyledInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#ede9f1',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#ccc',
    },
  },
}))(InputBase)

export const CustomInput = props => {
  const {register, errors} = useFormContext()
  const {className, label, name, ...rest} = props

  return (
    <React.Fragment>
      <label className={className}>
        {label && <span>{label}</span>}
        <StyledInput
          {...rest}
          name={name}
          className="per-100"
          variant="outlined"
          fullWidth
          inputRef={register}
        />
        {errors[name] && (
          <div className="error per-100">
            <span>{errors[name].message}</span>
          </div>
        )}
      </label>
    </React.Fragment>
  )
}
