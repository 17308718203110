import React from 'react'

export const AuthLoader = props => {
  return props.padding ? (
    <div className="per-100 h-100 horizon center middle ">
      <div className="logo-load">
        <img alt="loader" src={require('../assets/images/logo-fligram.png')} />
      </div>
    </div>
  ) : (
    <div className="per-100 h-100 horizon center middle">
      <div className="logo-load">
        <img alt="loader" src={require('../assets/images/logo-fligram.png')} />
      </div>
    </div>
  )
}
