import {inject, observer} from 'mobx-react'
import React from 'react'
import {ASide, UserIdButton} from 'materials'

export const UserLists = inject(
  'HistoryStore',
  'UIStore',
  'ProfileStore'
)(
  observer(props => {
    return (
      <ASide title="www.amazon.com" className="per-50" onClose={props.onClose}>
        <div id="scrollBar" className="scrollable-base">
          <table className="list-table">
            <thead>
              <tr>
                <td>User ID</td>
                <td>IP Address</td>
                <td>Install Date</td>
                <td>Device Type</td>
                <td>Location</td>
              </tr>
            </thead>
            <tbody>
              {[...Array(12)].map(i => (
                <tr>
                  <td>
                    <UserIdButton id="16aed661-9ed5-4acc-a247-24c34dbe4101" />
                  </td>
                  <td>193.123.32.12</td>
                  <td>12 Jun 2020 | 03:00pm</td>
                  <td>Mobile</td>
                  <td>United States</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ASide>
    )
  })
)
