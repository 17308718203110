import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async ({params = {}}) => {
    return await request.get(`/conversions/`, params)
  }

  static update = async () => null

  static delete = async () => null

  //same as read
  static detail = async ({params = {}}) => {
    return await request.get(`/conversions/`, params)
  }

  static selectable = async ({field}) =>
    await request.get(`/conversions/selectable`, {field})
  static searchable = async ({field, q}) =>
    await request.get(`/conversions/searchable`, {field, q})
}
