import {DateTime} from 'components'
import {ASide, MoreLess, QuickTips, UserIdButton} from 'materials'
import {inject, observer} from 'mobx-react'
import {qs} from 'helpers'
import React from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'

export const VisitModal = inject('HistoryStore')(
  observer(props => {
    const history = useHistory()
    const {search} = useLocation()
    const params = qs.parse(search)
    const {id} = useParams()
    const item = props.HistoryStore.getItemById(id)
    const data = item.unique_user_details || []

    const handleClose = () =>
      history.push(`/browsing-history?${qs.stringify(params)}`)

    return (
      <ASide title={`${id} visits`} onClose={handleClose}>
        <div className="scrollable-base" style={{width: 900}}>
          <table className="list-table-modal border-box per-99 mb-20">
            <thead>
              <tr>
                <td>User ID</td>
                <td>Install Date</td>
                <td>Last Ip</td>
                <td>
                  <div className="horizon left middle">
                    <p>City </p>
                    <QuickTips comment="Given location information might not be accurate" />
                  </div>
                </td>
                <td>
                  <div className="horizon left middle">
                    <p>Region </p>
                    <QuickTips comment="Given location information might not be accurate" />
                  </div>
                </td>
                <td>
                  <div className="horizon left middle">
                    <p>Country </p>
                    <QuickTips comment="Given location information might not be accurate" />
                  </div>
                </td>
                <td>Other Extensions</td>
              </tr>
            </thead>
            <tbody>
              {data.map(i => (
                <tr>
                  <td>
                    <UserIdButton id={i.user_id} />
                  </td>
                  <td>
                    <DateTime
                      timestamp={i.timestamp}
                      format={'MMM D YYYY | hh:mm A'}
                    />
                  </td>
                  <td>
                    <MoreLess content={i.ip_address} maxLimit={15} />
                  </td>
                  <td>
                    <MoreLess content={i.location_data.city} maxLimit={14} />
                  </td>
                  <td>
                    <MoreLess content={i.location_data.region} maxLimit={14} />
                  </td>
                  <td>
                    <MoreLess content={i.location_data.country} maxLimit={14} />
                  </td>
                  <td>
                    {i.installed_extensions ? i.installed_extensions : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ASide>
    )
  })
)
