import React, {useState} from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

export const Button = props => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleConfirm = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = async e => {
    setLoading(true)
    await props.onClick(e)
    handleClose()
    setLoading(false)
  }

  return (
    <React.Fragment>
      <button
        {...props}
        onClick={props.confirm ? handleConfirm : handleClick}
        type={props.type || 'button'}
        className={
          'btn horizon left ' +
          (props.className || 'middle pl-10 pr-10') +
          (props.loading ? ' submit-loader' : '')
        }>
        {props.loading || loading ? (
          <div className="btn-loader"> </div>
        ) : (
          props.icon || null
        )}
        {props.children}
        {props.name}
      </button>
      {props.confirm && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.confirm}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="p-10 horizon right middle">
              <button
                onClick={handleClose}
                className="btn basic mr-20"
                color="primary">
                {props.cancel || 'Cancel'}
              </button>
              <button
                onClick={handleClick}
                className="btn basic"
                color="primary"
                autoFocus>
                {props.ok || 'Ok'}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  )
}
