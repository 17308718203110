import React from 'react'
// import Notifications from "../Notifications/Notifications";

export const Footer = () => {
  return (
    <footer className="horizon right middle">
      <span></span>
    </footer>
  )
}
