import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {Conversion as Model, ConversionMeta as Meta} from '../models'
import {ConversionServices as Service} from '../services'

export default class ConversionStore {
  _list = new observable.map()
  meta = {}
  state = 'initial'

  _item = new observable.map()
  detailState = 'initial'
  detailMeta = {}

  _searchable = {}
  _selectable = {}

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _list: observable,
      meta: observable,
      state: observable,

      _item: observable,
      detailMeta: observable,
      detailState: observable,

      _searchable: observable,
      _selectable: observable,

      list: computed,
      item: computed,

      read: action,
      searchable: action,
      selectable: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  get item() {
    return [...this._item.values()]
  }

  read({onModal, page = 0, limit = 100, params = {}, id = null}) {
    const offset = page * limit
    if (onModal) {
      this.detailState = 'fetching'
      Service.detail({params}).then(res => {
        this.detailState = 'done'
        if (res.meta) {
          this.detailMeta = new Meta(res.meta)
        }
        if (res.data) {
          this._item = new observable.map()
          res.data.forEach(i => {
            const item = new Model(i || {})
            this._item.set(item.id, item)
          })
        }
      }, this.handleError)
    } else if (id) {
      this.detailState = 'fetching'
      Service.detail(id).then(res => {
        this.detailState = 'done'
        this._profile = new Model(res)
      }, this.handleError)
    } else {
      this.state = 'listing'
      Service.read({
        params: {...params, offset, limit},
      }).then(this.fetchSuccess, this.handleError)
    }
  }

  async selectable({field}) {
    return (
      toJS(this._selectable[field]) ||
      (this._selectable[field] = (await Service.selectable({field}))['data'][
        field
      ])
    )
  }

  async searchable({field, q}) {
    return (
      toJS(this._searchable[field + '-' + q]) ||
      (this._searchable[field + '-' + q] = (
        await Service.searchable({
          field,
          q,
        })
      )['data'][field])
    )
  }

  fetchSuccess = res => {
    if (res.meta) {
      this.meta = new Meta(res.meta)
    }
    if (res.data) {
      this._list = new observable.map()
      res.data.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.id, item)
      })
    }
    this.state = 'done'
  }

  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }
}
