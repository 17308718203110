import {DateTime, HeadLine, Loader, SummaryData, TableLabel} from 'components'
import {CustomDatePicker} from 'form'
import {MoreLess, UserIdButton} from 'materials'
import {inject, observer} from 'mobx-react'
import moment from 'moment'
import {qs} from 'helpers'
import React, {useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  AiOutlineExport,
  HiOutlineFolderDownload,
  MdRemoveFromQueue,
  VscVmActive,
} from 'react-icons/all'
import {useDefaultParams} from 'helpers'

export const ExtensionInstalls = inject(
  'ProfileStore',
  'UserStore',
  'UIStore'
)(
  observer(props => {
    const {today, yesterday} = props.ProfileStore
    const history = useHistory()
    const {search} = useLocation()
    const params = qs.parse(search)
    const [init, setInit] = useState(false)
    const {page = 0, from: _from, to: _to} = params
    const from = new Date(_from)
    const to = new Date(_to)

    console.log({today, yesterday})

    useDefaultParams({
      callback: setInit,
      once: {
        sort: JSON.stringify([{field: 'timestamp', direction: 'DESC'}]),
      },
      every: {
        from: props.UIStore.from || yesterday.startOf('days').toDate(),
        to: props.UIStore.to || today.endOf('days').toDate(),
      },
    })

    const startTime =
      moment(from).subtract(from.getTimezoneOffset(), 'minutes').unix() * 1000
    const endTime =
      moment(to).subtract(to.getTimezoneOffset(), 'minutes').unix() * 1000

    useDeepCompareEffect(() => {
      init &&
        props.UserStore.read({
          page,
          params: {
            ...params,
            from: startTime,
            to: endTime,
          },
        })
    }, [props.UserStore, page, params, from, to, init])
    const {state, meta, list} = props.UserStore

    console.log({state})

    const handleTimeChange = dates => {
      props.UIStore.from = dates[0]
      props.UIStore.to = dates[1]
      history.push(
        '?' +
          qs.stringify({
            from: dates[0],
            to: dates[1],
          })
      )
    }

    const chartData = [
      {
        label: '# of Download',
        name: 'all',
        value: (meta.all_time || {}).total_count,
      },
      {
        label: 'Active',
        name: 'active',
        value: (meta.all_time || {}).active_count,
      },
      {
        label: 'Removed',
        name: 'removed',
        value: (meta.all_time || {}).removed_count,
      },
    ]

    const labelData = [
      {
        label: '# of Download',
        icon: <HiOutlineFolderDownload />,
        name: 'all',
        value: (meta.filtered || {}).total_count,
      },
      {
        label: 'Active',
        icon: <VscVmActive />,
        name: 'active',
        value: (meta.filtered || {}).active_count,
      },
      {
        label: 'Removed',
        icon: <MdRemoveFromQueue />,
        name: 'removed',
        value: (meta.filtered || {}).removed_count,
      },
    ]

    return !init ? null : (
      <section className="vertical center">
        <HeadLine title="Extension Installs" className="sz-30 fw-300" />
        <div className="container mt-20 vertical top between">
          <div className="horizon between middle per-100">
            <div className="per-50 horizon left middle" />
            <div className="filter per-50 horizon right">
              <CustomDatePicker
                name="date_range"
                onChange={handleTimeChange}
                defaultValue={[from, to]}
              />
            </div>
          </div>

          <SummaryData
            className="mt-20"
            chartData={chartData}
            labelData={labelData}
            paramName="status"
            defaultValue="all"
          />
          {params.status === 'removed' && (
            <div className="mt-20 horizon right middle">
              <button className="btn basic horizon center middle">
                <AiOutlineExport /> Export Report
              </button>
            </div>
          )}

          <table className="list-table mt-20">
            <thead>
              <tr>
                <td>
                  <TableLabel
                    label="INSTALL DATE"
                    field="timestamp"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                {/*<td>*/}
                {/*  <TableLabel*/}
                {/*    label="REMOVED DATE"*/}
                {/*    field="timestamp"*/}
                {/*    meta={meta}*/}
                {/*    store={props.UserStore}*/}
                {/*  />*/}
                {/*</td>*/}
                <td>
                  <TableLabel
                    label="USER ID"
                    field="user_id"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="IP ADDRESS"
                    field="ip_address"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="CITY"
                    field="location_data.city"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="STATE"
                    field="location_data.region"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="COUNTRY"
                    field="location_data.country"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="LAST VISITED"
                    field="last_visited"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td />
              </tr>
            </thead>
            <tbody>
              {/*device_type: "Desktop / Laptop"*/}
              {/*honey_installed: true*/}
              {/*installation_status: "removed"*/}
              {/*ip_address: "172.222.180.51"*/}
              {/*last_visited: "https://mail.google.com/mail/u/0/#inbox/FMfcgxwHMjwVWlBcfbTKXMGgPwRgqzTm"*/}
              {/*rtm_installed: true*/}
              {/*timestamp: 1587055553729*/}
              {/*user_id: "1c2a034b-37a6-4165-8ace-09e51ab7ce03"*/}

              {state === 'done' &&
                list.map(i => (
                  <tr key={'extensions-' + i.id}>
                    <td nowrap={'nowrap'}>
                      <DateTime
                        timestamp={i.timestamp}
                        native
                        format={'MMM D YYYY | hh:mm A'}
                      />
                    </td>
                    {/*<td nowrap={'nowrap'}>-</td>*/}
                    <td nowrap={'nowrap'}>
                      <UserIdButton id={i.id} />
                    </td>
                    <td>
                      <MoreLess content={i.ip_address} maxLimit="16" />
                    </td>
                    <td>{i.city || i.province || '-'}</td>
                    <td>{i.state || i.region || '-'}</td>
                    <td>{i.country || '-'}</td>
                    <td>
                      <MoreLess content={i.last_visited} maxLimit="30" />
                    </td>
                    <td className="vertical left middle p-0">
                      <div className="removed-row">
                        {i.installation_status === 'removed' && (
                          <span className="message">
                            {i.installation_status}
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {state !== 'done' && <Loader />}
        </div>
      </section>
    )
  })
)
