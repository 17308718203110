import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {MdAttachMoney, MdHistory} from 'react-icons/all'
import {DateTime} from '..'
import {Loader} from '../Loader'
import {QuickTips} from 'materials'

export const Profile = inject('UserStore')(
  observer(props => {
    const {id} = props
    useEffect(() => {
      props.UserStore.read({id})
    }, [props.UserStore, id])

    const {item, detailState} = props.UserStore

    return (
      <div className="p-10 vertical left stretch">
        {detailState !== 'done' ? (
          <div className="mb-40 mt-20">
            <Loader />
          </div>
        ) : (
          <div className="horizon between stretch per-100">
            <table className="list-table-modal border-box per-49">
              <thead>
                <tr>
                  <td>User ID</td>
                  <td>: {item.id}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Install Date</td>
                  <td>
                    {': '}
                    <DateTime
                      timestamp={item.timestamp}
                      format={'MMM D YYYY | hh:mm A'}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Last Ip</td>
                  <td>: {item.ip_address}</td>
                </tr>
                {item.city && (
                  <tr>
                    <td className="horizon between middle">
                      <p>City </p>
                      <QuickTips comment="Given location information might not be accurate" />
                    </td>
                    <td>: {item.city}</td>
                  </tr>
                )}
                {item.suburb && (
                  <tr>
                    <td className="horizon between middle">
                      <p>Suburb </p>
                      <QuickTips comment="Given location information might not be accurate" />
                    </td>
                    <td>: {item.suburb}</td>
                  </tr>
                )}
                {item.province && (
                  <tr>
                    <td className="horizon between middle">
                      <p>Province </p>
                      <QuickTips comment="Given location information might not be accurate" />
                    </td>
                    <td>: {item.province}</td>
                  </tr>
                )}
                {item.county && (
                  <tr>
                    <td className="horizon between middle">
                      <p>County </p>
                      <QuickTips comment="Given location information might not be accurate" />
                    </td>
                    <td>: {item.county}</td>
                  </tr>
                )}
                {item.region && (
                  <tr>
                    <td className="horizon between middle">
                      <p>Region </p>
                      <QuickTips comment="Given location information might not be accurate" />
                    </td>
                    <td>: {item.region}</td>
                  </tr>
                )}
                {item.state && (
                  <tr>
                    <td className="horizon between middle">
                      <p>State </p>
                      <QuickTips comment="Given location information might not be accurate" />
                    </td>
                    <td>: {item.state}</td>
                  </tr>
                )}
                {item.country && (
                  <tr>
                    <td className="horizon between middle">
                      <p>Country </p>
                      <QuickTips comment="Given location information might not be accurate" />
                    </td>
                    <td>: {item.country}</td>
                  </tr>
                )}
                <tr>
                  <td>Other Extensions</td>
                  <td>
                    :{' '}
                    {item.installed_extensions
                      ? item.installed_extensions
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="vertical border-box top stretch per-49">
              <div className="item per-100 border-box horizon">
                <div className="icon">
                  <MdAttachMoney />
                </div>
                <div className="vertical p-10 left middle">
                  <p>Transactions</p>
                  <div className="horizon left middle mt-10">
                    <span className="affiliated horizon between middle">
                      {item.transaction_count}
                    </span>
                  </div>
                </div>
                <button onClick={() => props.onDefaultIndex(2)}>View</button>
              </div>
              <div className="item per-100 border-box horizon mt-20">
                <div className="icon">
                  <MdHistory />
                </div>
                <div className="vertical p-10 left middle">
                  <p>Website Browsing History</p>
                  <div className="horizon left middle mt-10">
                    <span className="affiliated horizon between middle">
                      <p>Affiliated</p>
                      {item.generic_count + item.checkout_count}
                    </span>
                    <span className="non-affiliated horizon between middle">
                      <p>Nonaffiliated</p>
                      {item.not_affiliate_count}
                    </span>
                  </div>
                </div>
                <button onClick={() => props.onDefaultIndex(1)}>View</button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  })
)
