import {qs} from './QueryString'
import {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

export {request} from './request'
export {qs} from './QueryString'

export const useDefaultParams = ({
  once = {},
  every = {},
  callback = _ => null,
}) => {
  const history = useHistory()
  const {search} = useLocation()
  const [skip, setSkip] = useState(false)
  const params = qs.parse(search)

  useEffect(() => {
    const historyPush = (params, newParams) => {
      console.log({params, newParams})
      if (JSON.stringify(newParams) !== JSON.stringify(params)) {
        console.log(qs.stringify(newParams))
        history.push('?' + qs.stringify(newParams))
      }
      callback(true)
    }

    const newParams = {...params}

    Object.entries(every).forEach(([key, val]) => {
      if (!params[key]) newParams[key] = val
    })

    const keys = Object.keys(params)
    // if there are keys, we can skip one time params
    if (skip || keys.length) {
      return historyPush(params, newParams)
    }

    setSkip(true)
    Object.entries(once).forEach(([key, val]) => {
      if (!params[key]) newParams[key] = val
    })

    return historyPush(params, newParams)
  }, [once, every, history, params, skip, setSkip, callback])
}
