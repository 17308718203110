import {inject, observer} from 'mobx-react'
import React from 'react'
import {MdArrowDropDown, MdArrowDropUp} from 'react-icons/all'
import {Link} from 'react-router-dom'
import packageJson from '../../../../package.json'

export const Dropdown = inject('UIStore')(
  observer(props => {
    const {name, label, links, className} = props
    const isOpen = props.UIStore.dropdown[name]

    const toggleMenu = e => {
      if (e) e.stopPropagation()
      props.UIStore.toggleDropDown(name)
      window.addEventListener('click', () => {
        props.UIStore.toggleDropDown(name, false)
      })
    }

    return (
      <div
        className={
          'menu horizon between stretch pl-10 pr-10 ' +
          (isOpen ? 'opened-menu' : '')
        }>
        <div
          className="menu-button horizon between middle"
          onClick={toggleMenu}>
          <p className="sz-14 ml-10 f-white">{label}</p>
          <MdArrowDropDown
            className={'f-white ' + (isOpen ? 'hide' : 'show')}
          />
          <MdArrowDropUp className={'f-white ' + (isOpen ? 'show' : 'hide')} />
        </div>
        <div
          className={
            'menu-dropdown horizon between stretch shadow-box ' +
            (isOpen ? 'show' : 'hide')
          }>
          <div
            id="scrollBar"
            className={'links vertical left top ' + className}>
            {links.map(i => (
              <Link key={i.to + i.label} to={i.to} onClick={toggleMenu}>
                {i.icon && i.icon}
                {i.label}
              </Link>
            ))}
            {props.children}
            <span className="version">v{packageJson.version}</span>
          </div>
        </div>
      </div>
    )
  })
)
