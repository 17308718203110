import {
  DateTime,
  HeadLine,
  Loader,
  Pagination,
  TableLabel,
  WebsiteOpener,
} from 'components'
import {CustomDatePicker} from 'form'
import {useDefaultParams} from 'helpers'
import {UserIdButton} from 'materials'
import {inject, observer} from 'mobx-react'
import moment from 'moment'
import {qs} from 'helpers'
import React, {useState} from 'react'
import {MdCheck, MdClose} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {SummaryData} from './SummaryData'

export const Transactions = inject(
  'TransactionStore',
  'UIStore',
  'ProfileStore'
)(
  observer(props => {
    const {today, yesterday} = props.ProfileStore

    const {search} = useLocation()
    const history = useHistory()
    const params = qs.parse(search)
    const [init, setInit] = useState(false)
    const {page = 0, from: _from, to: _to} = params
    const from = new Date(_from)
    const to = new Date(_to)

    useDefaultParams({
      once: {
        sort: '[{"field":"time","direction":"DESC"}]',
      },
      every: {
        from: props.UIStore.from || yesterday.startOf('days').toDate(),
        to: props.UIStore.to || today.endOf('days').toDate(),
      },
      callback: setInit,
    })

    const startTime =
      moment(from).subtract(from.getTimezoneOffset(), 'minutes').unix() * 1000
    const endTime =
      moment(to).subtract(to.getTimezoneOffset(), 'minutes').unix() * 1000

    useDeepCompareEffect(() => {
      init &&
        props.TransactionStore.read({
          page,
          params: {
            ...params,
            userID: params.user_id,
            from: startTime,
            to: endTime,
          },
        })
    }, [props.TransactionStore, page, from, to, params, init])
    const {state, meta, list} = props.TransactionStore
    const filteredMeta = meta.filtered_count || {}
    const allMeta = meta.all_time_count || {}

    const handleTimeChange = dates => {
      props.UIStore.from = dates[0]
      props.UIStore.to = dates[1]
      history.push(
        '?' +
          qs.stringify({
            from: dates[0],
            to: dates[1],
          })
      )
    }

    return !init ? null : (
      <section className="vertical center">
        <HeadLine title="Transactions" className="sz-30 fw-300">
          <div className="options horizon right middle">
            <div className="item">
              <p>Success :</p>
              <span>{allMeta.discounted_count || 0}</span>
            </div>
            <div className="item">
              <p>Unsuccessful :</p>
              <span>{allMeta.not_discounted_count || 0}</span>
            </div>
          </div>
        </HeadLine>
        <div className="container mt-20 vertical top between">
          <div className="horizon between middle per-100">
            <div>
              {/*<SelectBox*/}
              {/*	label="Sort By"*/}
              {/*	onChange={handleSort}*/}
              {/*	defaultValue={sortBy}*/}
              {/*>*/}
              {/*	<option value='time-desc'>Time-desc</option>*/}
              {/*	<option value='time-asc'>Time-asc</option>*/}
              {/*</SelectBox>*/}
            </div>
            <div className="filter per-50 horizon right">
              <div className="filter per-50 horizon right">
                <CustomDatePicker
                  onChange={handleTimeChange}
                  defaultValue={[from, to]}
                />
              </div>
            </div>
          </div>
          <SummaryData meta={filteredMeta} from={startTime} to={endTime} />

          {state !== 'done' ? (
            <Loader padding />
          ) : (
            <React.Fragment>
              <table className="list-table mt-20">
                <thead>
                  <tr>
                    <td>
                      <TableLabel
                        label="DATE TIME"
                        field="time"
                        meta={meta}
                        store={props.TransactionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="USER ID"
                        field="user_id"
                        meta={meta}
                        store={props.TransactionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="MERCHANT URL"
                        field="website_url"
                        meta={meta}
                        store={props.TransactionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="COUPON CODE"
                        field="successful_codes.code"
                        meta={meta}
                        store={props.TransactionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="DISCOUNT APPLIED"
                        field="is_discounted"
                        meta={meta}
                        store={props.TransactionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="TOTAL PRICE"
                        field="sort_old_price"
                        meta={meta}
                        store={props.TransactionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="SAVING AMOUNT"
                        field="sort_discount"
                        meta={meta}
                        store={props.TransactionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="FINAL PRICE"
                        field="sort_new_price"
                        meta={meta}
                        store={props.TransactionStore}
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {/*coupon_code: "WELCOME10"*/}
                  {/*discount: "$36.50"*/}
                  {/*is_discounted: true*/}
                  {/*new_price: "$352.03"*/}
                  {/*old_price: "$388.53"*/}
                  {/*record_id: "5f29145d07263997084d0404"*/}
                  {/*time: 1596527709687*/}
                  {/*user_id: "d3309140-73a3-4536-b640-30531a30a359"*/}
                  {/*website_url: "www.nicolemiller.com"*/}
                  {list.map(i => (
                    <tr key={'transaction-' + i.id}>
                      <td nowrap={'nowrap'}>
                        <DateTime
                          timestamp={i.timestamp}
                          format={'MMM D YYYY | hh:mm A'}
                        />
                      </td>
                      <td nowrap={'nowrap'}>
                        <UserIdButton id={i.user_id} />
                      </td>
                      <td>
                        <WebsiteOpener url={i.website_url} />
                      </td>
                      <td>{i.coupon_code}</td>
                      <td>
                        {i.is_discounted ? (
                          <MdCheck className="status active" />
                        ) : (
                          <MdClose className="status inactive" />
                        )}
                      </td>
                      <td>{i.old_price}</td>
                      <td>{i.discount}</td>
                      <td>{i.new_price}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                className="pagination mt-30 mb-40"
                page={page || 0}
                count={
                  filteredMeta.not_discounted_count +
                  filteredMeta.discounted_count
                }
                limit={100}
                range={9}
              />
            </React.Fragment>
          )}
        </div>
      </section>
    )
  })
)
