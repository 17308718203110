import {inject, observer} from 'mobx-react'
import React from 'react'
import {
  FiUsers,
  MdArrowDropDown,
  MdArrowDropUp,
  MdLink,
  MdPayment,
  FaRegListAlt,
  RiPriceTag3Line,
  BsArrowRepeat,
} from 'react-icons/all'
import {Link, useParams} from 'react-router-dom'
import packageJson from '../../../../package.json'

export const Menu = inject('UIStore')(
  observer(props => {
    const isOpen = props.UIStore.dropdown.Menu
    const {country = 'us'} = useParams()

    const toggleMenu = e => {
      if (e) e.stopPropagation()
      props.UIStore.toggleDropDown('Menu')
      window.addEventListener('click', () => {
        props.UIStore.toggleDropDown('Menu', false)
      })
    }

    return (
      <div
        className={
          'menu horizon between stretch pl-10 pr-10 ' +
          (isOpen ? 'opened-menu' : '')
        }>
        <div
          className="menu-button horizon between middle"
          onClick={toggleMenu}>
          <p className="sz-14 ml-10 f-white">Reporting</p>
          <MdArrowDropDown
            className={'f-white ' + (isOpen ? 'hide' : 'show')}
          />
          <MdArrowDropUp className={'f-white ' + (isOpen ? 'show' : 'hide')} />
        </div>
        <div
          className={
            'menu-dropdown horizon between stretch shadow-box ' +
            (isOpen ? 'show' : 'hide')
          }>
          <div className="links vertical left middle">
            <Link to={`/${country}/extension`} onClick={toggleMenu}>
              <MdLink />
              <span>Extension Installs</span>
            </Link>
            <Link to={`/${country}/transactions`} onClick={toggleMenu}>
              <FiUsers />
              <span>Transaction</span>
            </Link>
            <Link to={`/${country}/browsing-history`} onClick={toggleMenu}>
              <MdPayment />
              <span>Website Browsing History</span>
            </Link>
            <Link to={`/${country}/promo-code`} onClick={toggleMenu}>
              <RiPriceTag3Line />
              <span>Promo Code</span>
            </Link>
            <Link to={`/${country}/conversions`} onClick={toggleMenu}>
              <BsArrowRepeat />
              <span>Conversions</span>
            </Link>
            <Link to={`/${country}/action-logs`} onClick={toggleMenu}>
              <FaRegListAlt />
              <span>Action Logs</span>
            </Link>
            <span className="version">v{packageJson.version}</span>
          </div>
        </div>
      </div>
    )
  })
)
