import {inject, observer} from 'mobx-react'
import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {ManagementMenu, Menu} from '../'
import {CountryMenu} from './CountryMenu/CountryMenu'
import {Dropdown} from './Menu/Dropdown'
import {FaSignOutAlt} from 'react-icons/all'

export const Header = inject('AuthStore')(
  observer(props => {
    const history = useHistory()
    const {country = 'us'} = useParams()
    const handleHome = () => {
      history.push(`/${country}`)
    }

    const {me} = props.AuthStore

    return (
      <header className="horizon stretch between per-100">
        <div className="project horizon between ">
          <div className="brand horizon middle left">
            <button
              onClick={handleHome}
              style={{
                border: 0,
                background: 'transparent',
                padding: 0,
                cursor: 'pointer',
              }}>
              <img alt="evet" src={require('../../assets/images/logo.png')} />
            </button>
            <p className="sz-14 pl-20 f-white">Samba Extension</p>
          </div>
          <Menu />
          <ManagementMenu />
        </div>
        <div className="navigation horizon stretch right">
          <CountryMenu />
          {me && (
            <Dropdown
              name={me.name}
              label={
                <span
                  style={{display: 'block', minWidth: 90, textAlign: 'center'}}>
                  {me.name}
                </span>
              }
              links={[{label: 'Logout', icon: <FaSignOutAlt />, to: '/logout'}]}
            />
          )}
        </div>
      </header>
    )
  })
)
