import {Loader} from 'components'
import {UserIdButton} from 'materials'
import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'

export const UsageHistoryModal = inject(
  'UIStore',
  'PromoStore'
)(
  observer(props => {
    const {website, code} = props
    useEffect(() => {
      props.PromoStore.read({params: {website, code}})
    }, [props.PromoStore, website, code])

    const {item, detailState} = props.PromoStore
    return (
      <React.Fragment>
        {detailState !== 'done' ? (
          <Loader />
        ) : (
          <div className="usage-list p-10 modal-scroll-body">
            <table className="list-table-modal">
              <thead>
                <tr>
                  <td>TOTAL PRICE</td>
                  <td>SAVING AMOUNT</td>
                  <td>FINAL PRICE</td>
                  <td>User Id</td>
                </tr>
              </thead>
              <tbody>
                {item.map(i => (
                  <tr key={'promo-modal-' + i.id}>
                    <td>{i.ex_price}</td>
                    <td>{i.discount}</td>
                    <td>{i.new_price}</td>
                    <td>
                      <UserIdButton id={i.user_id} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    )
  })
)
