import {inject, observer} from 'mobx-react'
import React from 'react'
import {ASide, MoreLess, UserIdButton} from 'materials'

export const ActionLists = inject(
  'HistoryStore',
  'UIStore',
  'ProfileStore'
)(
  observer(props => {
    return (
      <ASide title="www.amazon.com" className="per-50" onClose={props.onClose}>
        <div id="scrollBar" className="scrollable-base">
          <table className="list-table">
            <thead>
              <tr>
                <td>User ID</td>
                <td>Current URL</td>
                <td>Action Type</td>
                <td>Targeted URL</td>
                <td>Copied Code</td>
              </tr>
            </thead>
            <tbody>
              {[...Array(12)].map(i => (
                <tr>
                  <td>
                    <UserIdButton id="16aed661-9ed5-4acc-a247-24c34dbe4101" />
                  </td>
                  <td>
                    <MoreLess
                      maxLimit="20"
                      content="https://www.amazon.com/gp/cart/view.html?ref_=nav_cart"
                    />
                  </td>
                  <td>Auto Apply</td>
                  <td>
                    <MoreLess
                      maxLimit="20"
                      content="https://www.amazon.com/gp/cart/view"
                    />
                  </td>
                  <td>FREE20</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ASide>
    )
  })
)
