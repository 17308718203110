import React from 'react'
import {MdSearch} from 'react-icons/md'

export const SearchInput = props => {
  return (
    <form method="GET" autoComplete="off">
      <label
        className={
          'border-box per-100  horizon between middle headline-search ' +
          props.className
        }>
        <input
          type="text"
          name={props.name || 'search'}
          placeholder={props.placeholder || 'Search'}
          className="per-100"
          onClick={props.onClick || null}
          onChange={props.onChange || null}
          onKeyPress={props.onKeyPress || null}
          defaultValue={props.defaultValue}
        />
        <MdSearch />
      </label>
    </form>
  )
}
