import {computed} from 'mobx'

export class Meta {
  searchable = []
  selectable = []
  sortable = []

  constructor(data) {
    // console.log('history meta model', {data})
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class Conversion {
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }

  @computed
  get id() {
    return this['merchant_id'] + this['user_id'] + this['timestamp']
  }
}
