import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {User as Model, UserMeta as Meta} from '../models'
import {UserServices as Service} from '../services'

export default class UserStore {
  _list = new observable.map()
  meta = {}
  state = 'initial'
  detailState = 'initial'
  _searchable = {}
  _selectable = {}

  constructor(Stores) {
    this.stores = Stores
    // this.model = User;
    // this.service = UserServices;

    makeObservable(this, {
      _list: observable,
      meta: observable,
      state: observable,
      detailState: observable,
      _searchable: observable,
      _selectable: observable,

      list: computed,

      read: action,
      searchable: action,
      selectable: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  read = async ({page = 0, limit = 100, params = {}, id = null}) => {
    const offset = page * limit
    if (id) {
      this.detailState = 'fetching'
      await Service.detail(id).then(res => {
        this.detailState = 'done'
        this._profile = new Model(res)
      }, this.handleError)
      this.state = 'done'
    } else {
      this.state = 'fetching'
      await Service.read({
        params: {...params, offset, limit},
      }).then(this.fetchSuccess, this.handleError)
    }
  }

  async selectable({field}) {
    return (
      toJS(this._selectable[field]) ||
      (this._selectable[field] = (await Service.selectable({field}))['data'][
        field
      ])
    )
  }

  async searchable({field, q}) {
    return (
      toJS(this._searchable[field + '-' + q]) ||
      (this._searchable[field + '-' + q] = (
        await Service.searchable({
          field,
          q,
        })
      )['data'][field])
    )
  }

  fetchSuccess = res => {
    if (res.meta) {
      this.meta = new Meta(res.meta)
    }
    if (res.data) {
      this._list = new observable.map()
      res.data.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.id, item)
      })
    }
    this.state = 'done'
    console.log(this.state, 'fetchSuccess')
  }

  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }
}
