import {inject, observer} from 'mobx-react'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {LayoutDefault, LayoutGuest} from 'layouts'

import {
  BrowsingHistory,
  ExtensionInstalls as Dashboard,
  Logout,
  NoMatch,
  PromoCode,
  Rules,
  Transactions,
  Conversions,
  RedirectTo,
  ActionLogs,
} from './pages'

const RestrictedRoute = ({
  component: Component,
  layout: Layout = LayoutDefault,
  authenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/logout',
              //state: {from: localStorage.getItem('location')}
            }}
          />
        )
      }
    />
  )
}

const GuestRoute = ({
  component: Component,
  layout: Layout = LayoutGuest,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

export const Routing = inject(
  'UIStore',
  'AuthStore',
  'ProfileStore'
)(
  observer(props => {
    const {authenticated} = props.AuthStore

    return (
      <Switch>
        <GuestRoute path="/logout" component={Logout} layout={LayoutDefault} />

        <RestrictedRoute
          exact
          path="/:country"
          component={Dashboard}
          layout={LayoutDefault}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/:country/dashboard"
          layout={LayoutDefault}
          component={Dashboard}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/:country/extension"
          layout={LayoutDefault}
          component={Dashboard}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/:country/transactions"
          layout={LayoutDefault}
          component={Transactions}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/:country/browsing-history/:id?"
          layout={LayoutDefault}
          component={BrowsingHistory}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/:country/promo-code"
          layout={LayoutDefault}
          component={PromoCode}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/:country/rules/:page(add|edit)?/:id?"
          layout={LayoutDefault}
          component={Rules}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/:country/conversions"
          layout={LayoutDefault}
          component={Conversions}
          authenticated={authenticated}
        />
        <RestrictedRoute
          exact
          path="/:country/action-logs"
          layout={LayoutDefault}
          component={ActionLogs}
          authenticated={authenticated}
        />

        <GuestRoute
          path="/:country/**"
          component={NoMatch}
          layout={LayoutDefault}
        />

        <GuestRoute component={RedirectTo} layout={LayoutDefault} />
      </Switch>
    )
  })
)
