import {inject, observer} from 'mobx-react'
import React from 'react'
import {FaUser} from 'react-icons/all'
import {UserProfile} from '../'
import {MoreLess} from 'materials'

export const UserIdButton = inject('UIStore')(
  observer(props => {
    const showModal = () => {
      const content = <UserProfile id={props.id} />
      props.UIStore.modalOpen({
        content,
        type: 'empty',
        onClose: () => {
          // props.history.push("/extension");
        },
      })
    }

    return (
      <button
        onClick={showModal}
        className={'user-id-button horizon between middle ' + props.className}>
        <div className="icon">{props.icon ? props.icon : <FaUser />}</div>
        <p>
          <MoreLess
            content={props.id}
            maxLimit={props.id?.split('-')[0].length}
          />
        </p>
      </button>
    )
  })
)
