import {inject, observer} from 'mobx-react'
import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Routing} from 'routing'
import {ScrollToTop, AuthLoader} from 'components'
import useDeepCompareEffect from 'use-deep-compare-effect'

export const App = inject('AuthStore')(
  observer(props => {
    useDeepCompareEffect(() => {
      if (!props.AuthStore.authenticated)
        window.location.href = process.env.REACT_APP_AUTH
      else if (!['/logout', '/pending'].includes(window.location.pathname))
        props.AuthStore.handleAuth().then()
    }, [props.AuthStore])

    const {authenticated, me} = props.AuthStore
    return authenticated && !me ? (
      <AuthLoader />
    ) : (
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/" component={Routing} />
        </Switch>
      </Router>
    )
  })
)
