import {
  DateTime,
  HeadLine,
  Loader,
  Pagination,
  TableLabel,
  WebsiteOpener,
} from 'components'
import {CustomDatePicker} from 'form'
import {useDefaultParams} from 'helpers'
import {QuickTips, ToggleMenu} from 'materials'
import {inject, observer} from 'mobx-react'
import moment from 'moment'
import {qs} from 'helpers'
import React, {useState} from 'react'
import {Link, useHistory, useLocation, useParams} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {VisitModal} from './VisitModal'

export const BrowsingHistory = inject(
  'HistoryStore',
  'UIStore',
  'ProfileStore'
)(
  observer(props => {
    const {today, yesterday} = props.ProfileStore

    const history = useHistory()
    const {search} = useLocation()
    const params = qs.parse(search)
    const {id} = useParams()
    const [init, setInit] = useState(false)
    const {page = 0, from: _from, to: _to} = params
    const from = new Date(_from)
    const to = new Date(_to)

    useDefaultParams({
      once: {
        type: 'generic',
        sort: '[{"field":"unique_visit","direction":"DESC"}]',
      },
      every: {
        from: props.UIStore.from || yesterday.startOf('days').toDate(),
        to: props.UIStore.to || today.endOf('days').toDate(),
      },
      callback: setInit,
    })

    const startTime =
      moment(from).subtract(from.getTimezoneOffset(), 'minutes').unix() * 1000
    const endTime =
      moment(to).subtract(to.getTimezoneOffset(), 'minutes').unix() * 1000

    useDeepCompareEffect(() => {
      init &&
        props.HistoryStore.read({
          page,
          params: {
            ...params,
            from: startTime,
            to: endTime,
          },
        })
    }, [props.HistoryStore, page, params, from, to, init])
    const {state, meta, list} = props.HistoryStore

    const handleTypeChange = value => {
      params.type = value
      historyPush()
    }

    const historyPush = () => {
      history.push('?' + qs.stringify(params))
    }

    const handleTimeChange = dates => {
      props.UIStore.from = dates[0]
      props.UIStore.to = dates[1]
      history.push(
        '?' +
          qs.stringify({
            from: dates[0],
            to: dates[1],
          })
      )
    }

    return !init ? null : (
      <section className="vertical center">
        <HeadLine title="Website Browsing History" className="sz-30 fw-300">
          <div className="options horizon right middle">
            <div className="item">
              <p># of Websites :</p>
              <span>{meta.count || 0}</span>
            </div>
          </div>
        </HeadLine>

        {id && <VisitModal />}

        <div className="container mt-20 vertical top between">
          <div className="horizon between middle per-100">
            <ToggleMenu
              size="small"
              options={[
                {label: 'Generic', value: 'generic'},
                {label: 'Checkout', value: 'checkout'},
                {label: 'Non-Affiliated', value: 'not_affiliate'},
                {label: 'Cart No Rule', value: 'cart_no_rule'},
              ]}
              onChange={handleTypeChange}
              defaultValue={params.type}
            />

            <div className="filter per-50 horizon right">
              <div className="filter per-50 horizon right">
                <CustomDatePicker
                  onChange={handleTimeChange}
                  defaultValue={[from, to]}
                />
              </div>
            </div>
          </div>
          {state === 'listing' ? (
            <Loader />
          ) : (
            <table className="list-table mt-20">
              <thead>
                <tr>
                  <td>
                    <TableLabel
                      label="DOMAIN"
                      field="domain"
                      meta={meta}
                      store={props.HistoryStore}
                    />
                  </td>
                  <td>
                    <TableLabel
                      label="LAST VISITED"
                      field="last_visit"
                      meta={meta}
                      store={props.HistoryStore}
                    />
                  </td>
                  <td>
                    <div className="horizon middle">
                      <TableLabel
                        label="UNIQUE VISIT"
                        field="unique_visit"
                        meta={meta}
                        store={props.HistoryStore}
                      />
                      <QuickTips comment="How many different user entered this website" />
                    </div>
                  </td>
                  <td>
                    <TableLabel
                      label="TOTAL VISIT"
                      field="total_visit"
                      meta={meta}
                      store={props.HistoryStore}
                    />
                  </td>
                  <td>
                    <TableLabel
                      label="AVG VISIT"
                      field="average_visit_per_user"
                      meta={meta}
                      store={props.HistoryStore}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* average_visit: 15
              domain: "www.dickblick.com"
              last_visit: 1594650117881
              total_visit: 15
              unique_visit: 1 */}
                {list.map(i => (
                  <tr key={'extensions-' + i.id}>
                    <td nowrap={'nowrap'}>
                      <WebsiteOpener url={i.domain} limit={30} />
                    </td>

                    <td>
                      <DateTime
                        timestamp={i.last_visit}
                        format={'MMM D YYYY | hh:mm A'}
                      />
                    </td>

                    <td nowrap={'nowrap'}>
                      <Link
                        to={`/browsing-history/${i.id}?${qs.stringify(
                          params
                        )}`}>
                        {i.unique_visit}
                      </Link>
                    </td>

                    <td>
                      {/* <button className="btn basic" onClick={showVisited}> */}
                      {i.total_visit}
                      {/* </button> */}
                    </td>

                    <td nowrap={'nowrap'}>{i.average_visit_per_user}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {state !== 'listing' && (
            <Pagination
              className="pagination mt-30 mb-40"
              page={page || 0}
              count={meta.count}
              limit={100}
              range={9}
            />
          )}
        </div>
      </section>
    )
  })
)
