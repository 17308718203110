import {DateTime, HeadLine, Loader, Pagination, TableLabel} from 'components'
import {CustomDatePicker} from 'form'
import {useDefaultParams} from 'helpers'
import {MoreLess, UserIdButton} from 'materials'
import {inject, observer} from 'mobx-react'
import moment from 'moment'
import {qs} from 'helpers'
import React, {useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'

export const Conversions = inject(
  'ProfileStore',
  'ConversionStore',
  'UIStore'
)(
  observer(props => {
    const {today} = props.ProfileStore
    const history = useHistory()
    const {search} = useLocation()
    const params = qs.parse(search)
    const [init, setInit] = useState(false)
    const {page = 0, from: _from, to: _to} = params
    const from = new Date(_from)
    const to = new Date(_to)

    const oneWeekAgo = today.add(-7, 'days')

    useDefaultParams({
      callback: setInit,
      once: {
        sort: '[{"field":"timestamp","direction":"DESC"}]',
      },
      every: {
        from: props.UIStore.from || oneWeekAgo.startOf('days').toDate(),
        to: props.UIStore.to || today.endOf('days').toDate(),
      },
    })

    const startTime =
      moment(from).subtract(from.getTimezoneOffset(), 'minutes').unix() * 1000
    const endTime =
      moment(to).subtract(to.getTimezoneOffset(), 'minutes').unix() * 1000

    useDeepCompareEffect(() => {
      init &&
        props.ConversionStore.read({
          page,
          params: {
            ...params,
            from: startTime,
            to: endTime,
          },
        })
    }, [props.ConversionStore, page, params, from, to, init])
    const {state, meta, list} = props.ConversionStore

    const handleTimeChange = dates => {
      props.UIStore.from = dates[0]
      props.UIStore.to = dates[1]
      history.push(
        '?' +
          qs.stringify({
            from: dates[0],
            to: dates[1],
          })
      )
    }

    return !init ? null : (
      <section className="vertical center">
        <HeadLine title="Conversions Report" className="sz-30 fw-300" />
        <div className="container mt-20 vertical top between">
          <div className="horizon between middle per-100">
            <div className="per-50 horizon left middle" />
            <div className="filter per-50 horizon right">
              <CustomDatePicker
                name="date_range"
                onChange={handleTimeChange}
                defaultValue={[from, to]}
              />
            </div>
          </div>

          {state !== 'listing' ? (
            <React.Fragment>
              <table className="list-table mt-20">
                <thead>
                  <tr>
                    <td>
                      <TableLabel
                        label="TIME"
                        field="timestamp"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="MERCHANT"
                        field="merchant_name"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="COMMISSION"
                        field="total_commission"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="SALES AMOUNT"
                        field="total_amount"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="SALES COUNT"
                        field="sales_count"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="RETURN"
                        field="return_count"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="TRANSACTIONS"
                        field="transaction_count"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="USER ID"
                        field="user_id"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {state === 'done' &&
                    list.map(
                      ({
                        id,
                        merchant_name,
                        return_count,
                        sales_count,
                        timestamp,
                        total_amount,
                        total_commission,
                        transaction_count,
                        user_id,
                      }) => (
                        <tr key={'conversions-' + id}>
                          <td nowrap={'nowrap'}>
                            <DateTime
                              timestamp={timestamp}
                              native
                              format={'MMM D YYYY | hh:mm A'}
                            />
                          </td>
                          <td nowrap={'nowrap'}>
                            <MoreLess content={merchant_name} maxLimit={20} />
                          </td>
                          <td nowrap={'nowrap'}>{total_commission}</td>
                          <td>{total_amount}</td>
                          <td>{sales_count}</td>
                          <td>{return_count}</td>
                          <td>{transaction_count}</td>
                          <td className="vertical left middle p-0">
                            <UserIdButton id={user_id} />
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
              <Pagination
                className="pagination mt-30 mb-40"
                page={page || 0}
                count={meta.total_count}
                limit={100}
                range={9}
              />
            </React.Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </section>
    )
  })
)
