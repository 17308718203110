import {HeadLine, Loader, Pagination, TableLabel} from 'components'
import {useDefaultParams} from 'helpers'
import {inject, observer} from 'mobx-react'
import moment from 'moment'
import {qs} from 'helpers'
import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {FiEye} from 'react-icons/all'
import {ActionLists} from 'pages/ActionLog/ActionsList'
import {UserLists} from 'pages/ActionLog/UserList'

export const ActionLogs = inject(
  'ProfileStore',
  'ConversionStore',
  'UIStore'
)(
  observer(props => {
    const {today} = props.ProfileStore
    // const history = useHistory()
    const {search} = useLocation()
    const params = qs.parse(search)
    const [init, setInit] = useState(false)
    const {page = 0, from: _from, to: _to} = params
    const from = new Date(_from)
    const to = new Date(_to)

    const oneWeekAgo = today.add(-7, 'days')

    const [actionList, setActionList] = useState(false)
    const [userList, setUserList] = useState(false)

    const ShowActions = () => {
      setActionList(!actionList)
    }
    const ShowUsers = () => {
      setUserList(!userList)
    }

    useDefaultParams({
      callback: setInit,
      once: {
        sort: '[{"field":"timestamp","direction":"DESC"}]',
      },
      every: {
        from: props.UIStore.from || oneWeekAgo.startOf('days').toDate(),
        to: props.UIStore.to || today.endOf('days').toDate(),
      },
    })

    const startTime =
      moment(from).subtract(from.getTimezoneOffset(), 'minutes').unix() * 1000
    const endTime =
      moment(to).subtract(to.getTimezoneOffset(), 'minutes').unix() * 1000

    useDeepCompareEffect(() => {
      init &&
        props.ConversionStore.read({
          page,
          params: {
            ...params,
            from: startTime,
            to: endTime,
          },
        })
    }, [props.ConversionStore, page, params, from, to, init])
    const {state, meta, list} = props.ConversionStore
    //
    // const handleTimeChange = dates => {
    //   props.UIStore.from = dates[0]
    //   props.UIStore.to = dates[1]
    //   history.push(
    //     '?' +
    //       qs.stringify({
    //         from: dates[0],
    //         to: dates[1],
    //       })
    //   )
    // }

    return !init ? null : (
      <section className="vertical center">
        <HeadLine title="Action Log" className="sz-30 fw-300" />
        {actionList && <ActionLists onClose={ShowActions} />}
        {userList && <UserLists onClose={ShowUsers} />}
        <div className="container mt-20 vertical top between">
          {state !== 'listing' ? (
            <React.Fragment>
              <table className="list-table mt-20">
                <thead>
                  <tr>
                    <td>
                      <TableLabel
                        label="UNIQ DOMAIN"
                        field="timestamp"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="ACTION TYPE"
                        field="merchant_name"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="ACTION COUNT"
                        field="total_commission"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="USER COUNT"
                        field="total_amount"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {state === 'done' &&
                    list.map(
                      ({
                        id,
                        merchant_name,
                        return_count,
                        sales_count,
                        timestamp,
                        total_amount,
                      }) => (
                        <tr key={'conversions-' + id}>
                          <td nowrap={'nowrap'}>www.domain.com</td>
                          <td nowrap={'nowrap'}>Trending</td>
                          <td nowrap={'nowrap'}>
                            <button
                              onClick={ShowActions}
                              className="btn basic p-0">
                              4 <FiEye className="ml-6" />
                            </button>
                          </td>
                          <td>
                            <button
                              onClick={ShowUsers}
                              className="btn basic p-0">
                              2 <FiEye className="ml-6" />
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
              <Pagination
                className="pagination mt-30 mb-40"
                page={page || 0}
                count={meta.total_count}
                limit={100}
                range={9}
              />
            </React.Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </section>
    )
  })
)
