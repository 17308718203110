import {
  DateTime,
  HeadLine,
  Loader,
  Pagination,
  SummaryData,
  TableLabel,
} from 'components'
import {CustomDatePicker} from 'form'
import {useDefaultParams} from 'helpers'
import {MoreLess} from 'materials'
import {inject, observer} from 'mobx-react'
import moment from 'moment'
import {qs} from 'helpers'
import React, {useState} from 'react'
import {
  CgPlayListCheck,
  FaRegTimesCircle,
  FaSortAmountUpAlt,
  GiTicket,
  MdCheck,
  MdClose,
} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {UsageHistoryModal} from './UsageHistoryModal'

export const PromoCode = inject(
  'PromoStore',
  'UIStore',
  'ProfileStore'
)(
  observer(props => {
    const {today, yesterday} = props.ProfileStore
    const {search} = useLocation()
    const history = useHistory()
    const params = qs.parse(search)
    const [init, setInit] = useState(false)
    const {page = 0, from: _from, to: _to} = params
    const from = new Date(_from)
    const to = new Date(_to)

    useDefaultParams({
      callback: setInit,
      once: {
        search: '[{"field":"worked","q":["True"],"include":true}]',
      },
      every: {
        from: props.UIStore.from || yesterday.startOf('days').toDate(),
        to: props.UIStore.to || today.endOf('days').toDate(),
      },
    })

    const startTime =
      moment(from).subtract(from.getTimezoneOffset(), 'minutes').unix() * 1000
    const endTime =
      moment(to).subtract(to.getTimezoneOffset(), 'minutes').unix() * 1000

    useDeepCompareEffect(() => {
      init &&
        props.PromoStore.read({
          params: {
            ...params,
            page,
            from: startTime,
            to: endTime,
          },
        })
    }, [props.PromoStore, page, from, to, params, init])
    const {state, meta, list, limit} = props.PromoStore

    const usageModal = item => {
      const title = (
        <div className="horizon middle">
          <GiTicket className="mr-10" />
          <span className="mr-20">{item.code} </span>
          <a
            href={'//' + item.merchant}
            target="_blank"
            rel="noreferrer noopener">
            <MoreLess content={item.merchant} maxLimit="30" />
          </a>
        </div>
      )
      const content = (
        <UsageHistoryModal website={item.merchant} code={item.code} />
      )
      props.UIStore.modalOpen({
        title,
        content,
        type: 'normal',
        onClose: null,
      })
    }

    const handleTimeChange = dates => {
      props.UIStore.from = dates[0]
      props.UIStore.to = dates[1]
      history.push(
        '?' +
          qs.stringify({
            from: dates[0],
            to: dates[1],
          })
      )
    }

    const chartData = [
      {label: 'Successfully', value: (meta.all_time || {}).total_successful},
      {label: 'UnSuccessfully', value: (meta.all_time || {}).total_failed},
      {
        label: 'Total Saving',
        value: (meta.all_time || {}).total_saved,
        noColor: true,
      },
    ]

    const labelData = [
      {
        label: 'Successfully',
        icon: <CgPlayListCheck />,
        name: JSON.stringify([{field: 'worked', q: ['True'], include: true}]),
        value: (meta.filtered_stats || {}).total_successful,
      },
      {
        label: 'UnSuccessfully',
        icon: <FaRegTimesCircle />,
        name: JSON.stringify([{field: 'worked', q: ['False'], include: true}]),
        value: (meta.filtered_stats || {}).total_failed,
      },
      {
        label: 'Saving Amount',
        icon: <FaSortAmountUpAlt />,
        value: (meta.filtered_stats || {}).total_saved,
        noClick: true,
      },
    ]

    return !init ? null : (
      <section className="vertical center">
        <HeadLine title="Promo Code" className="sz-30 fw-300" />
        <div className="container mt-20 vertical top between">
          <div className="horizon between middle per-100">
            <div className="per-100 horizon right middle">
              <div className="filter per-50 horizon right">
                <CustomDatePicker
                  onChange={handleTimeChange}
                  defaultValue={[from, to]}
                />
              </div>
            </div>
          </div>

          <SummaryData
            className="mt-20"
            chartData={chartData}
            labelData={labelData}
            paramName="search"
            defaultValue="worked"
          />
          {state !== 'done' ? (
            <Loader />
          ) : (
            <React.Fragment>
              <table className="list-table mt-20">
                <thead>
                  <tr>
                    <td>
                      {/*["averageBasket", "averageDiscount", "totalDiscount", "count", "lastUsedAt"]*/}
                      <TableLabel
                        label="MERCHANT"
                        field="merchant"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="PROMO CODE"
                        field="code"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="# of USAGE"
                        field="count"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="Last Used"
                        field="lastUsedAt"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="TOTAL SAVING AMOUNT"
                        field="totalDiscount"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="AVG SAVING AMOUNT"
                        field="averageDiscount"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="AVG BASKET AMOUNT"
                        field="averageBasket"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="WORKED"
                        field="worked"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {list.map(i => (
                    <tr key={'extensions-' + i.id}>
                      <td>
                        <a
                          href={'//' + i.merchant}
                          target="_blank"
                          rel="noreferrer noopener">
                          <MoreLess content={i.merchant} maxLimit="30" />
                        </a>
                      </td>
                      <td>
                        <MoreLess content={i.code} maxLimit="30" />
                      </td>
                      <td>
                        <button
                          className="btn basic p-0"
                          onClick={() => usageModal(i)}>
                          {i.usage_count}
                        </button>
                      </td>
                      <td>
                        <DateTime
                          timestamp={i.last_used_time}
                          format={'MMM D YYYY | hh:mm A'}
                        />
                      </td>
                      <td>
                        {i.total_discount === '$0' ? '-' : i.total_discount}
                      </td>
                      <td>
                        {i.average_discount === '$0' ? '-' : i.average_discount}
                      </td>
                      <td>
                        {i.average_basket === '$0' ? '-' : i.average_basket}
                      </td>
                      <td>
                        {i.worked ? (
                          <MdCheck className="status active" />
                        ) : (
                          <MdClose className="status inactive" />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                className="pagination mt-30 mb-40"
                page={page || 0}
                count={(meta.filtered_stats || {}).total_count}
                limit={limit}
                range={9}
              />
            </React.Fragment>
          )}
        </div>
      </section>
    )
  })
)
