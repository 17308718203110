import {inject, observer} from 'mobx-react'
import React from 'react'
import {MdArrowDropDown, MdArrowDropUp, MdPayment} from 'react-icons/all'
import {Link, useParams} from 'react-router-dom'
import packageJson from '../../../../package.json'

export const ManagementMenu = inject('UIStore')(
  observer(props => {
    const isOpen = props.UIStore.dropdown.ManagementMenu
    const {country = 'us'} = useParams()

    const toggleMenu = e => {
      if (e) e.stopPropagation()
      props.UIStore.toggleDropDown('ManagementMenu')
      window.addEventListener('click', () => {
        props.UIStore.toggleDropDown('ManagementMenu', false)
      })
    }

    return (
      <div
        className={
          'menu horizon between stretch pl-10 pr-10 ' +
          (isOpen ? 'opened-menu' : '')
        }>
        <div
          className="menu-button horizon between middle"
          onClick={toggleMenu}>
          <p className="sz-14 ml-10 f-white">Management</p>
          <MdArrowDropDown
            className={'f-white ' + (isOpen ? 'hide' : 'show')}
          />
          <MdArrowDropUp className={'f-white ' + (isOpen ? 'show' : 'hide')} />
        </div>
        <div
          className={
            'menu-dropdown horizon between stretch shadow-box ' +
            (isOpen ? 'show' : 'hide')
          }>
          <div className="links vertical left middle">
            <Link to={`/${country}/rules`} onClick={toggleMenu}>
              <MdPayment />
              <span>Rules</span>
            </Link>
            <span className="version">v{packageJson.version}</span>
          </div>
        </div>
      </div>
    )
  })
)
