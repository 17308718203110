import {request} from 'helpers'

export class Services {
  static signIn = async ({email, password, remember}) => {
    try {
      const res = await request.post('/login/', {email, password}, false).then()
      request.deleteCookies()
      request.setCookies({
        access_token: res.AccessToken,
        refresh_token: res.RefreshToken,
        id_token: res.IdToken,
        expires_in: res.ExpiresIn,
        remember,
        email,
      })
      return true
    } catch (e) {
      throw e
    }
  }

  static signOut = () => request.deleteCookies()
}
