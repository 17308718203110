import {inject, observer} from 'mobx-react'
import moment from 'moment'
import React from 'react'

export const DateTime = inject('ProfileStore')(
  observer(props => {
    const format = props.format || 'MMM D, YYYY hh:mm A'
    const date = props.timestamp
      ? moment.utc(props.timestamp)
      : props.native
      ? moment(props.date)
      : props.ProfileStore.date(props.date)

    //.utcOffset(props.utcOffset || -7)

    return <span>{date.isValid() ? date.format(format) : 'N/A'}</span>
  })
)
