import {InputBase, MenuItem, Select, withStyles} from '@material-ui/core'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {QuickTips} from 'materials'

const StyledSelect = withStyles(theme => ({
  root: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#ede9f1',
    border: '1px solid #e3e3e3',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#ccc',
    },
  },
}))(InputBase)
const CustomMenuItem = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  selected: {},
}))(MenuItem)

export const CustomSelect = props => {
  const {errors, control} = useFormContext()
  const {name, label, className, defaultValue, options, info, ...rest} = props

  return (
    <React.Fragment>
      <label className={className}>
        <span className="horizon left middle per-100">
          {label}
          {info && <QuickTips className="horizon left top" comment={info} />}
        </span>
        <Controller
          as={
            <Select
              {...rest}
              input={<StyledSelect />}
              // MenuProps={{
              // 	disableScrollLock: true,
              // }}
            >
              {(options || []).map(i => (
                <CustomMenuItem key={'option-' + i.label} value={i.value}>
                  {i.label}
                </CustomMenuItem>
              ))}
            </Select>
          }
          name={name}
          control={control}
        />
        {errors[name] && (
          <div className="error per-100">
            <span>{errors[name].message}</span>
          </div>
        )}
      </label>
    </React.Fragment>
  )
}
