import React from 'react'
import {MdClose} from 'react-icons/md'
import {useHistory, useLocation} from 'react-router-dom'
import {Loader} from '../Loader'

export const ASide = props => {
  const history = useHistory()
  const location = useLocation()
  const {
    id,
    title,
    // actions,
    // show,
    type,
    showFormButton,
    // hideFormButton,
    loading,
    children,
    onClose,
    className,
  } = props

  const isClosed = showFormButton
    ? showFormButton.closePush === location.pathname
    : true

  const handleClose = () => {
    if (props.hasOwnProperty('onClose')) onClose()
    document.body.classList.remove('overflow-hidden')
  }

  const eventRemover = () => {
    document.body.removeEventListener('keyup', escClose)
  }

  const escClose = e => {
    if (e.key === 'Escape') {
      handleClose()
      eventRemover()
    }
  }

  document.body.addEventListener('keyup', escClose)

  return (
    <div id={id || 'SideModal'} className={'modal-base show ' + type}>
      <div className={'modal ' + className} id="scrollBar">
        <div className="modal-title horizon between middle per-100">
          {type === 'right' ? (
            <div className="horizon left">
              <MdClose id="closeModalIcon" onClick={handleClose} />
              <h2>{title || '-'}</h2>
            </div>
          ) : (
            <div className="horizon between per-100">
              <h2>{title || '-'}</h2>
              <MdClose id="closeModalIcon" onClick={handleClose} />
            </div>
          )}

          {showFormButton &&
            (isClosed ? (
              <button
                className="btn check"
                onClick={() => history.push(showFormButton.openPush)}>
                {showFormButton.openText}
              </button>
            ) : (
              <button
                className="btn check"
                onClick={() => history.push(showFormButton.closePush)}>
                {showFormButton.closeText}
              </button>
            ))}
        </div>
        <div className="modal-content">
          {loading ? <Loader padding /> : children}
        </div>
      </div>
    </div>
  )
}
