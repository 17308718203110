export class Meta {
  searchable = []
  selectable = []
  sortable = []

  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class User {
  constructor(data) {
    if (data) {
      Object.entries(data).forEach(([k, i]) => (this[k] = i))
      const address = this.location_data || {}
      Object.entries(address).forEach(([k, i]) => (this[k] = i))
    }
  }

  get id() {
    return this['user_id']
  }

  get session_id() {
    return this['sessionID']
  }
}
