import * as Sentry from '@sentry/browser'
import {Provider} from 'mobx-react'
import React from 'react'
import ReactDOM from 'react-dom'
import {stores} from 'stores'
import {App} from './App'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'production')
  Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN})

const render = Component => {
  ReactDOM.render(
    <Provider {...stores}>
      <Component />
    </Provider>,
    document.getElementById('root')
  )
}

serviceWorker.register({
  onUpdate: registration => {
    registration.unregister().then(() => {
      window.location.reload()
      // const message = (
      //   <span>
      //     New version is released. Please
      //     <button className="btn-link" onClick={() => window.location.reload()}>
      //       refresh
      //     </button>{' '}
      //     the page
      //   </span>
      // );
      // stores.SystemMessageStore.setNewRelease(true, message);
      //
      // setTimeout(() => window.location.reload(), 50);
    })
  },
})
render(App)
