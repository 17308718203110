import {computed, makeObservable, observable} from 'mobx'

export class Account {
  constructor(data) {
    console.log('Account Model', {data})

    this.id = data.id
    this.username = data.username
    this.first = data.first_name
    this.last = data.last_name
    this.is_verified = data.is_verified
    this._products = data.products
    this.project = process.env.REACT_APP_PROJECT
    makeObservable(this, {
      id: observable,
      username: observable,
      first: observable,
      last: observable,
      is_verified: observable,
      name: computed,
      products: computed,
    })
  }

  get name() {
    return this.first
  }

  get fullname() {
    return this.first + ' ' + this.last
  }

  get products() {
    return this._products.map(i => ({
      id: i.id,
      project: i.canonical_name,
      name: i.name,
      access: i.is_admin ? 'ADMIN' : 'USER',
      url: i.redirect_url,
      data: i.custom_attributes,
    }))
  }

  get product() {
    return this.products.find(i => i.project === this.project)
  }
}
