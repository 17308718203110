import React from 'react'
import {WebsiteOpener} from 'components'

export const Item = props => {
  const {data} = props

  const handleDetail = () => props.onDetail(data.id)

  return (
    <tr>
      <td>
        <WebsiteOpener url={data.domain} limit={20} />
      </td>
      <td onClick={handleDetail}>{data.id}</td>
      <td>{data.type}</td>
    </tr>
  )
}
